.games-btn{
    background-color: #ffebdb !important;
    box-shadow: none !important;
}

.games-btn-text{
    color: #ff9f56 !important
}

.nextui-c-csEDlc-ivNdeP-css{
    --nextui--backdropOpacity: 0.1 !important
}

.player-picture{
    width: 125px !important;
    height: 150px !important;
    margin-left: 10px;
    border-radius: 20px;
    object-fit: cover;
}

.player-card:hover{
    cursor: pointer;
    opacity: 1;
    transform: scale(1.025);
    transition: transform 0.25s ease-in-out;
}

.user{
    color: #F31260
}
.book{
    color: #0072F5;
}
.dollar{
    color: #17C964;
}
.soccerball{
    color: #7828C8;
    transform: rotateZ(90deg);
}
.team{
    color: #F5A524;
}
.chat{
    margin-top: 5px;
    color: #F5A524;
}


