.nextui-c-yrlUe-kJGdUE-activeColor-default{
    --nextui--navbarItemActiveColor: #ff9f56 !important;
    --nextui--navbarItemHighlightBackgroundColor: #ffebdb !important;
    --nextui--navbarItemHighlightTextColor: #ff9f56!important;
}

.nextui-navbar-brand{
    font-weight: 610;
}

.nextui-c-kzikkm-hfnKRU-color-default{
    --nextui--linkColor: #ff9f56 !important ;
}

.ig-link{
    color: white !important
}

.instagram-logo{
    padding: 10%;
    border-radius: 05px;
    background: #f09433; 
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
    background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}